<script>
export default {
    props: {
        formData: Object,
        fullpage: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            iframeUrl: `${process.env.VUE_APP_PAYMENT_PAGE_URL}`,
            iframeHeight: "100vh",
            iframeWrapperHeight: 29
        }
    },
    computed: {
        brandName() {
            return this.$store.state.auth.currentUser.brand_name;
        },
    },
    created() {
        if(!this.fullpage) {
            window.addEventListener("message", (event) => {
                if (event.origin === this.iframeUrl) {
                    this.iframeHeight = event.data.height * 2.47 + "px";
                    this.iframeWrapperHeight = event.data.height + 29;
                }
            });
        }
    },
    methods: {
        updatePreview() {
            // Send updated form configuration to the iframe
            const iframe = this.$refs.previewIframe;

            this.formData.merchant_details.name = this.brandName;
            
            iframe.contentWindow.postMessage({pageData: this.formData}, "*");
        },
    },
    watch: {
        formData: {
            deep: true, // Watch nested properties
            handler() {
                this.updatePreview();
            },
        },
    }
}
</script>
<template>
    <div :style="`margin-left: 24px; margin-right: 24px; border-radius: 16px; border: 1px solid var(--gray-10, rgba(0, 0, 0, 0.10)); box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.05), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.04), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02); height: ${iframeWrapperHeight}px;`">
        <div class="d-flex justify-content-between" style="background: var(--White, #FFF); height: 29px; padding: var(--4, 4px) 24px; border-top-left-radius: 16px; border-top-right-radius: 16px;">
            <div class="d-flex align-items-center" style="gap: 6px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 10.5C7.76142 10.5 10 8.26142 10 5.5C10 2.73858 7.76142 0.5 5 0.5C2.23858 0.5 0 2.73858 0 5.5C0 8.26142 2.23858 10.5 5 10.5Z" fill="#DCDAD5" fill-opacity="0.3"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 10.5C7.76142 10.5 10 8.26142 10 5.5C10 2.73858 7.76142 0.5 5 0.5C2.23858 0.5 0 2.73858 0 5.5C0 8.26142 2.23858 10.5 5 10.5Z" fill="#DCDAD5" fill-opacity="0.3"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 10.5C7.76142 10.5 10 8.26142 10 5.5C10 2.73858 7.76142 0.5 5 0.5C2.23858 0.5 0 2.73858 0 5.5C0 8.26142 2.23858 10.5 5 10.5Z" fill="#DCDAD5" fill-opacity="0.3"/>
                </svg>
            </div>

            <div class="d-flex justify-content-between" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30); padding: 4px 8px; width: 361px;">
                <div></div>
                <div style="font-size: 8px; font-weight: 400; color: var(--Black, #151515); font-family: Inter;">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97999 6.493L5.97999 6.49296C5.97542 6.01489 5.97042 5.49082 6.10365 5.04672C6.17795 4.79907 6.28454 4.62098 6.42403 4.50399C6.55662 4.39279 6.7638 4.29883 7.11748 4.29883C7.47117 4.29883 7.67835 4.39279 7.81094 4.50399C7.95043 4.62098 8.05702 4.79907 8.13131 5.04672C8.26454 5.49079 8.25954 6.01481 8.25499 6.49286V6.49286C8.25418 6.57808 8.25338 6.66184 8.25338 6.74342L9.25338 6.7434C9.25338 6.68154 9.2543 6.61192 9.2553 6.53601C9.26144 6.06928 9.27071 5.36461 9.08914 4.75937C8.9794 4.39357 8.78949 4.01955 8.45355 3.7378C8.11071 3.45025 7.66354 3.29883 7.11748 3.29883C6.57143 3.29883 6.12426 3.45025 5.78142 3.7378C5.44548 4.01955 5.25557 4.39357 5.14582 4.75937C4.96424 5.36465 4.97353 6.06936 4.97969 6.5361C4.98069 6.61198 4.9816 6.68157 4.9816 6.74342L5.9816 6.7434C5.9816 6.66187 5.9808 6.57816 5.97999 6.493ZM5.15435 6.74367H9.08046C9.44185 6.74367 9.73482 7.03663 9.73482 7.39802V10.3426C9.73482 10.704 9.44185 10.997 9.08046 10.997H5.15435C4.79296 10.997 4.5 10.704 4.5 10.3426V7.39802C4.5 7.03663 4.79296 6.74367 5.15435 6.74367Z" fill="#DCDAD5"/>
                </svg>
                <!-- {{pageBaseUrl}}/{{ form.customizePageDetails.page_ref }} -->
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                <g clip-path="url(#clip0_4801_39954)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.17943 4.61047C2.31195 4.11558 2.55343 3.65657 2.88619 3.26701C3.21895 2.87746 3.63458 2.56721 4.10268 2.35896C4.57077 2.15071 5.0795 2.04973 5.59165 2.0634C6.1038 2.07707 6.60642 2.20505 7.06274 2.43797C7.103 2.45847 7.14691 2.47083 7.19196 2.47436C7.237 2.47788 7.2823 2.4725 7.32526 2.45852C7.36823 2.44454 7.40802 2.42223 7.44236 2.39287C7.47671 2.36351 7.50493 2.32768 7.52542 2.28741C7.54592 2.24714 7.55828 2.20323 7.56181 2.15819C7.56533 2.11315 7.55995 2.06785 7.54597 2.02488C7.53199 1.98192 7.50968 1.94213 7.48032 1.90779C7.45096 1.87344 7.41513 1.84522 7.37486 1.82472C6.5065 1.38163 5.50927 1.26041 4.56004 1.48255C3.61081 1.7047 2.77094 2.25585 2.18938 3.03827C1.60781 3.82068 1.32215 4.78378 1.38306 5.75675C1.44397 6.72972 1.84751 7.64968 2.52211 8.35346C3.1967 9.05724 4.09876 9.49935 5.06828 9.60138C6.0378 9.70341 7.01213 9.45876 7.81846 8.91083C8.62478 8.36291 9.21099 7.54711 9.4731 6.60813C9.73522 5.66915 9.65631 4.66768 9.25036 3.78135C9.21235 3.69839 9.14293 3.63392 9.05738 3.60214C8.97184 3.57036 8.87717 3.57386 8.79421 3.61188C8.71124 3.6499 8.64678 3.71931 8.615 3.80486C8.58322 3.89041 8.58672 3.98507 8.62474 4.06804C8.85457 4.56954 8.96034 5.11898 8.93309 5.66997C8.90585 6.22095 8.74639 6.75729 8.4682 7.23367C8.19001 7.71004 7.80125 8.11247 7.33477 8.40696C6.86829 8.70144 6.33778 8.87932 5.78807 8.92559C5.23835 8.97185 4.68558 8.88513 4.17644 8.67276C3.6673 8.46039 3.21675 8.12859 2.86285 7.70541C2.50895 7.28223 2.2621 6.78009 2.14315 6.24141C2.0242 5.70273 2.03664 5.14333 2.17943 4.61047Z" fill="#DCDAD5"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.60099 0.100645C5.66545 0.0362022 5.75287 0 5.84402 0C5.93517 0 6.02259 0.0362022 6.08705 0.100645L7.8058 1.8194C7.83781 1.85133 7.86321 1.88926 7.88054 1.93102C7.89787 1.97278 7.90679 2.01756 7.90679 2.06277C7.90679 2.10799 7.89787 2.15276 7.88054 2.19452C7.86321 2.23628 7.83781 2.27421 7.8058 2.30615L6.08705 4.0249C6.05509 4.05681 6.01716 4.08212 5.97542 4.09937C5.93368 4.11663 5.88895 4.12549 5.84378 4.12546C5.79861 4.12543 5.75389 4.1165 5.71218 4.09919C5.67046 4.08187 5.63256 4.05651 5.60065 4.02455C5.53619 3.96 5.50002 3.8725 5.50008 3.78128C5.50011 3.73611 5.50904 3.69139 5.52635 3.64968C5.54367 3.60796 5.56903 3.57006 5.60099 3.53815L7.07636 2.06277L5.60099 0.587395C5.56898 0.555464 5.54358 0.517531 5.52625 0.475768C5.50892 0.434006 5.5 0.389235 5.5 0.34402C5.5 0.298805 5.50892 0.254035 5.52625 0.212272C5.54358 0.17051 5.56898 0.132577 5.60099 0.100645Z" fill="#DCDAD5"/>
                </g>
                <defs>
                    <clipPath id="clip0_4801_39954">
                    <rect width="11" height="11" fill="white"/>
                    </clipPath>
                </defs>
                </svg>
            </div>

            <div class="d-flex align-items-center" style="gap: 20px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.61339 2.44865C2.63951 2.47484 2.67055 2.49562 2.70472 2.5098C2.73889 2.52398 2.77552 2.53128 2.81251 2.53128C2.84951 2.53128 2.88614 2.52398 2.92031 2.5098C2.95448 2.49562 2.98551 2.47484 3.01164 2.44865L4.50001 0.959714L5.98839 2.44865C6.01454 2.4748 6.04558 2.49554 6.07975 2.5097C6.11391 2.52385 6.15053 2.53113 6.18751 2.53113C6.22449 2.53113 6.26111 2.52385 6.29528 2.5097C6.32944 2.49554 6.36049 2.4748 6.38664 2.44865C6.41279 2.4225 6.43353 2.39146 6.44768 2.35729C6.46183 2.32313 6.46912 2.28651 6.46912 2.24953C6.46912 2.21255 6.46183 2.17593 6.44768 2.14176C6.43353 2.10759 6.41279 2.07655 6.38664 2.0504L4.69914 0.362901C4.67301 0.336709 4.64198 0.315929 4.60781 0.30175C4.57364 0.287572 4.53701 0.280273 4.50001 0.280273C4.46302 0.280273 4.42639 0.287572 4.39222 0.30175C4.35805 0.315929 4.32701 0.336709 4.30089 0.362901L2.61339 2.0504C2.5872 2.07653 2.56642 2.10756 2.55224 2.14173C2.53806 2.1759 2.53076 2.21253 2.53076 2.24953C2.53076 2.28652 2.53806 2.32315 2.55224 2.35732C2.56642 2.39149 2.5872 2.42253 2.61339 2.44865Z" fill="#DCDAD5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 6.46875C4.57459 6.46875 4.64613 6.43912 4.69887 6.38637C4.75162 6.33363 4.78125 6.26209 4.78125 6.1875V1.125C4.78125 1.05041 4.75162 0.978871 4.69887 0.926126C4.64613 0.873382 4.57459 0.84375 4.5 0.84375C4.42541 0.84375 4.35387 0.873382 4.30113 0.926126C4.24838 0.978871 4.21875 1.05041 4.21875 1.125V6.1875C4.21875 6.26209 4.24838 6.33363 4.30113 6.38637C4.35387 6.43912 4.42541 6.46875 4.5 6.46875Z" fill="#DCDAD5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40625 7.875C1.40625 8.09878 1.49514 8.31339 1.65338 8.47162C1.81161 8.62986 2.02622 8.71875 2.25 8.71875H6.75C6.97378 8.71875 7.18839 8.62986 7.34662 8.47162C7.50486 8.31339 7.59375 8.09878 7.59375 7.875V3.9375C7.59375 3.71372 7.50486 3.49911 7.34662 3.34088C7.18839 3.18264 6.97378 3.09375 6.75 3.09375H5.90625C5.83166 3.09375 5.76012 3.12338 5.70738 3.17613C5.65463 3.22887 5.625 3.30041 5.625 3.375C5.625 3.44959 5.65463 3.52113 5.70738 3.57387C5.76012 3.62662 5.83166 3.65625 5.90625 3.65625H6.75C6.82459 3.65625 6.89613 3.68588 6.94887 3.73863C7.00162 3.79137 7.03125 3.86291 7.03125 3.9375V7.875C7.03125 7.94959 7.00162 8.02113 6.94887 8.07387C6.89613 8.12662 6.82459 8.15625 6.75 8.15625H2.25C2.17541 8.15625 2.10387 8.12662 2.05113 8.07387C1.99838 8.02113 1.96875 7.94959 1.96875 7.875V3.9375C1.96875 3.86291 1.99838 3.79137 2.05113 3.73863C2.10387 3.68588 2.17541 3.65625 2.25 3.65625H3.09375C3.16834 3.65625 3.23988 3.62662 3.29262 3.57387C3.34537 3.52113 3.375 3.44959 3.375 3.375C3.375 3.30041 3.34537 3.22887 3.29262 3.17613C3.23988 3.12338 3.16834 3.09375 3.09375 3.09375H2.25C2.02622 3.09375 1.81161 3.18264 1.65338 3.34088C1.49514 3.49911 1.40625 3.71372 1.40625 3.9375V7.875Z" fill="#DCDAD5"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.73855 1.56174C4.67526 1.49845 4.58941 1.46289 4.4999 1.46289C4.41039 1.46289 4.32455 1.49845 4.26125 1.56174C4.19796 1.62504 4.1624 1.71088 4.1624 1.80039V4.16289H1.7999C1.71039 4.16289 1.62455 4.19845 1.56125 4.26174C1.49796 4.32504 1.4624 4.41088 1.4624 4.50039C1.4624 4.5899 1.49796 4.67575 1.56125 4.73904C1.62455 4.80233 1.71039 4.83789 1.7999 4.83789H4.1624V7.20039C4.1624 7.2899 4.19796 7.37575 4.26125 7.43904C4.32455 7.50233 4.41039 7.53789 4.4999 7.53789C4.58941 7.53789 4.67526 7.50233 4.73855 7.43904C4.80184 7.37575 4.8374 7.2899 4.8374 7.20039V4.83789H7.1999C7.28941 4.83789 7.37526 4.80233 7.43855 4.73904C7.50184 4.67575 7.5374 4.5899 7.5374 4.50039C7.5374 4.41088 7.50184 4.32504 7.43855 4.26174C7.37526 4.19845 7.28941 4.16289 7.1999 4.16289H4.8374V1.80039C4.8374 1.71088 4.80184 1.62504 4.73855 1.56174Z" fill="#DCDAD5"/>
                </svg>
            </div>
        </div>

        <iframe
            ref="previewIframe"
            :src="iframeUrl + '?isPreview=true'"
            :style="`display: block; border: none; width: ${!fullpage ? '200%' : '100%'}; height: ${iframeHeight}; ${!fullpage && 'transform: scale(0.5); transform-origin: top left;'} overflow: hidden; border-radius: 16px; box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.25);`"
            @load="updatePreview"
        ></iframe>
    </div>
</template>