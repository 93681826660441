<script>
import Layout from "../../layouts/main";
import Breadcrumb from "../../../components/breadcrumb.vue";
import { VueEditor, Quill } from "vue2-editor";
import Country from "../../../components/country.vue";
import Currency from "../../../components/currency.vue";
import PaymentMethodModal from "../../../components/payment-method-modal.vue";
import Common from "../../../helpers/Common";
import draggable from 'vuedraggable';

import {
  UPLOAD_PAGE_IMAGE_IN_STORE,
  CREATE_PAYMENT_PAGE_RECORD_IN_STORE,
  UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE
} from '@/state/mutations.type';

import {
  CHECK_PAYMENT_PAGE_CUTOM_URL_AVAILABLITY,
  FETCH_PAYMENT_PAGE_DETAILS
} from "@/state/actions.type";

import { alphaNum, required, requiredIf, email, minLength, maxLength, minValue } from "vuelidate/lib/validators";
import { isValidPhoneNumber } from "libphonenumber-js";
import SwitchInput from "../../../components/switch-input.vue";
import cloneDeep from "lodash.clonedeep";
import PaymentPagePreview from "./payment-page-preview.vue";

let fonts = Quill.import('formats/font')
// Specify Quill fonts
const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana', 'Sans Serif', 'Serif', 'Monospace'];
const fontNames = fontList.map(font => font.toLowerCase().replace(/\s/g, "-"));

fonts.whitelist = fontNames;
Quill.register(fonts, true);

let fontStyles = "";
fontList.forEach(function(font) {
  let fontName = font.toLowerCase().replace(/\s/g, "-");
  fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
    "content: '" + font + "';" +
    "font-family: '" + font + "', sans-serif;" +
    "}" +
    ".ql-font-" + fontName + "{" +
    " font-family: '" + font + "', sans-serif;" +
    "}";
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

const greaterThan = (param) => ((value, vm) =>((vm[param] != 0 && !vm[param]) ? true : value > vm[param]));
const lessThan = (param) => ((value, vm) => ((vm[param] != 0 && !vm[param]) ? true : value < vm[param]));
const greaterThanZero = (value) => {
  if(value != 0 && !value) {
    return true;
  }
  return value > 0;
}

const checkDuplicateOption = (value, vm) => {
  let findOption = vm.drop_down_options.options.find(el => el.value == vm.option_text);

  if(findOption) {
    return false;
  }
  return true;
}

const checkOptionIsRequired = (value, vm) => {
  if(vm.type === 'dropdown') {
    return vm.drop_down_options.options.length !== 0
  }

  return true;
}

export default {
  components: {
    Layout,
    Breadcrumb,
    VueEditor,
    Country,
    Currency,
    PaymentMethodModal,
    draggable,
    SwitchInput,
    PaymentPagePreview
  },
  data() {
    return {
      activeTab: "Page Details",
      customToolbar: [
        'bold', 'italic', 'underline',
        { 'color': [] },
        { 'align': [] },
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'background': [] },
        'link', 'image',
        { 'font': fonts.whitelist },
        { 'size': [] },
      ],
      form: {
        pageDetails: {
          title: "",
          description: "",
          currency: "",
          country_code: "",
          terms_and_conditions: "",
          show_social_media_options: false,
          email_address: this.$store.state.auth.currentUser.support_email,
          phone_number: this.$store.state.auth.currentUser.support_phone
        },
        customizePageDetails: {
          page_ref: "",
          send_broker_email_notification: false,
          send_email_confirmation: false,
          broker_emails: [],
          fixed_fields: {
            amount: null,
            is_stock_enabled: false,
            available_stock_quantity: null,
            amount_field_type: "FIXED",
            minimum_amount: null,
            maximum_amount: null,
            minimum_quantity: null,
            maximum_quantity: null
          },
          custom_fields: {
            fields: [
              {
                type: "name",
                is_mandatory: true,
                title: "Name",
                tooltip: "",
                description: "Enter your name",
                sort_order: 1,
                is_active: true,
                drop_down_options: {
                  options: []
                },
                option_text: ""
              },
              {
                type: "email",
                is_mandatory: true,
                title: "Email",
                tooltip: "",
                description: "Enter your email",
                sort_order: 2,
                is_active: true,
                drop_down_options: {
                  options: []
                },
                option_text: ""
              },
              {
                type: "phone_number",
                is_mandatory: true,
                title: "Phone Number",
                tooltip: "",
                description: "Enter your phone number",
                sort_order: 3,
                is_active: true,
                drop_down_options: {
                  options: []
                },
                option_text: ""
              }
            ]
          },
        },
        branding: {
          brand_primary_color: "#FF7628",
          brand_secondary_color: "#f2f2f2",
          is_dark_mode: false,
          pay_button_text: "Pay Now"
        },
        merchant_details: {
          logo: "",
        },
      },
      successUrl: `${process.env.VUE_APP_CHECKOUT_BASE_URL}/success.html`,
      failureUrl: `${process.env.VUE_APP_CHECKOUT_BASE_URL}/failure.html`,
      pendingUrl: `${process.env.VUE_APP_CHECKOUT_BASE_URL}/pending.html`,
      fieldTypeOption: {
        text: 'Text',
        name: 'Name',
        number: 'Number',
        email: 'Email',
        url: 'Link/URL',
        phone_number: 'Phone Number',
        textarea: 'Large Textarea',
        dropdown: 'Dropdown',
        datepicker: 'Date Picker',
        checkbox: 'Checkbox',
      },
      fieldTypes: [
        { value: 'text', text: 'Text', icon: `${require('@/assets/images/small-icons/T.svg')}` },
        { value: 'name', text: 'Name', icon: `${require('@/assets/images/small-icons/aA.svg')}` },
        { value: 'number', text: 'Number', icon: `${require('@/assets/images/small-icons/123.svg')}` },
        { value: 'email', text: 'Email', icon: `${require('@/assets/images/small-icons/@.svg')}` },
        { value: 'phone_number', text: 'Phone Number', icon: `${require('@/assets/images/small-icons/phone.svg')}` },
        { value: 'url', text: 'Link/URL', icon: `${require('@/assets/images/small-icons/link.svg')}` },
        { value: 'textarea', text: 'Large Textarea', icon: `${require('@/assets/images/small-icons/Textarea.svg')}` },
        { value: 'dropdown', text: 'Dropdown', icon: `${require('@/assets/images/small-icons/Dropdown.svg')}` },
        { value: 'datepicker', text: 'Date Picker', icon: `${require('@/assets/images/small-icons/calendar.svg')}` },
        { value: 'checkbox', text: 'Checkbox', icon: `${require('@/assets/images/small-icons/tick-square.svg')}` },
      ],
      testcheckbox: "",
      isCustomUrl: false,
      brokerNotificationEmail: "",
      amountConfigActive: false,
      currentActiveAmountConfig: "",
      toastOpen: false,
      pageBaseUrl: process.env.VUE_APP_PAYMENT_PAGE_BASE_URL,
      selectedPaymentMethods: [],
      paymentMethods: null,
      showTermsAndConditions: false,
      isEdit: false
    }
  },

  validations: {
    form: {
      pageDetails: {
        title: { required },
        description: { required },
        currency: { required },
        country_code: { required },
        terms_and_conditions: {},
        show_social_media_options: {},
        email_address: { required, email },
        phone_number: { required, isValidPhoneNumber },
      },
      customizePageDetails: {
        page_ref: {
          requiredIf: requiredIf(function() {
            return this.isCustomUrl && !this.isEdit;
          }),
          minLength: minLength(10),
          maxLength: maxLength(40),
          alphaNum
        },
        fixed_fields: {
          amount: { requiredIf: requiredIf((el) => el.amount_field_type !== "CUSTOM_USER_INPUT"), greaterThanZero },
          amount_field_type: { required },
          minimum_amount: {
            greaterThanZero,
            lessThan: lessThan('maximum_amount'),
            requiredIf: requiredIf((el) => el.amount_field_type === "CUSTOM_USER_INPUT")
          },
          maximum_amount: {
            greaterThanZero,
            greaterThan: greaterThan('minimum_amount'),
            requiredIf: requiredIf((el) => el.amount_field_type === "CUSTOM_USER_INPUT")
          },
          minimum_quantity: {
            greaterThanZero,
            lessThan: lessThan('maximum_quantity'),
            requiredIf: requiredIf((el) => el.amount_field_type === "FIXED_WITH_QUANTITY")
          },
          maximum_quantity: {
            greaterThanZero,
            greaterThan: greaterThan('minimum_quantity'),
            requiredIf: requiredIf((el) => el.amount_field_type === "FIXED_WITH_QUANTITY")
          },
          is_stock_enabled: { required },
          available_stock_quantity: {
            minValue: minValue(0),
            requiredIf: requiredIf((el) => el.is_stock_enabled)
          }
        },
        custom_fields: {
          fields: {
            $each: {
              type: { required },
              title: { required },
              drop_down_options: {
                checkOptionIsRequired,
                options: {}
              },
              option_text: {
                checkDuplicateOption
              }
            }
          }
        },
      },
      branding: {
        pay_button_text: { required },
      }
    },
    brokerNotificationEmail: { email }
  },

  computed: {
    items() {
      return [
        {
          text: "Payment Pages",
          to: { name: 'Payment Pages' }
        },
        {
          text: this.isEdit ? "Update Payment Page" : "Create New Payment Page",
          active: true
        }
      ]
    },
    brandName() {
      return this.$store.state.auth.currentUser.brand_name;
    },
    userName() {
      return this.$store.state.auth.currentUser.name;
    },
    payloadForPaymentPage() {
      const customFields = this.form.customizePageDetails.custom_fields.fields.map((item, index) => {
        item.sort_order = index + 1;
        return item;
      });
      
      const payload = {
        "title": this.form.pageDetails.title,
        "description": this.form.pageDetails.description,
        "success_url": this.successUrl,
        "failure_url": this.failureUrl,
        "pending_url": this.pendingUrl,
        "currency": this.form.pageDetails.currency,
        "amount": this.form.customizePageDetails.fixed_fields.amount == null ? this.form.customizePageDetails.fixed_fields.minimum_amount : this.form.customizePageDetails.fixed_fields.amount,
        "country_code": this.form.pageDetails.country_code,
        "merchant_details": {
          "logo": this.form.merchant_details.logo,
          "brand_primary_color": this.form.branding.brand_primary_color,
          "brand_secondary_color": this.form.branding.brand_secondary_color,
          "email_address": this.form.pageDetails.email_address,
          "phone_number": this.form.pageDetails.phone_number,
        },
        "notify_by_email": false,
        "link_description": this.form.pageDetails.title,
        "terms_and_conditions": this.form.pageDetails.terms_and_conditions,
        "custom_fields": {
          "fields": customFields
        },
        "fixed_fields": {
          "pay_button_text": this.form.branding.pay_button_text,
          "amount_field_type": this.form.customizePageDetails.fixed_fields.amount_field_type,
          "minimum_amount": this.form.customizePageDetails.fixed_fields.minimum_amount || 0,
          "maximum_amount": this.form.customizePageDetails.fixed_fields.maximum_amount || 0,
          "minimum_quantity": this.form.customizePageDetails.fixed_fields.minimum_quantity || 0,
          "maximum_quantity": this.form.customizePageDetails.fixed_fields.maximum_quantity || 0
        },
        "available_stock_quantity": this.form.customizePageDetails.fixed_fields.available_stock_quantity || 0,
        "is_stock_enabled": this.form.customizePageDetails.fixed_fields.is_stock_enabled,
        "show_social_media_options": this.form.pageDetails.show_social_media_options,
        "send_email_confirmation": this.form.customizePageDetails.send_email_confirmation,
        "broker_emails": this.form.customizePageDetails.broker_emails,
        "send_broker_email_notification": this.form.customizePageDetails.send_broker_email_notification,
        "is_dark_mode": this.form.branding.is_dark_mode,
        "page_ref": this.form.customizePageDetails.page_ref,
        "page_type": "NORMAL",
        "preview_page_ref": "",
        "chosen_payment_methods": this.selectedPaymentMethods,
      }

      return payload;
    }
  },

  created() {
    if(this.$route.query.pageRef) {
      this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_DETAILS}`, {
        pageRef: this.$route.query.pageRef,
        submerchantKey: this.$route.query.key,
        isPreviewPage: false
      }).then((response) => {
        const content = cloneDeep(response.content);

        if(this.$route.query.duplicate == "true") {
          content.custom_fields.fields = content.custom_fields.fields.filter((field) => {
            field.editing = false;
            delete field.key;
            return field.is_active;
          }).sort((a, b) => a.sort_order - b.sort_order);

          content.page_ref = "";
          this.isEdit = false;
        } else {
          content.custom_fields.fields = content.custom_fields.fields.sort((a, b) => a.sort_order - b.sort_order);
          this.isEdit = true;
        }

        this.paymentMethods = content.chosen_payment_methods;

        this.form = {
          pageDetails: {
            title: content.title,
            description: content.description,
            currency: content.currency,
            country_code: content.country_code,
            terms_and_conditions: content.terms_and_conditions,
            show_social_media_options: content.show_social_media_options,
            email_address: content.merchant_details.email_address,
            phone_number: content.merchant_details.phone_number
          },
          customizePageDetails: {
            page_ref: content.page_ref,
            send_broker_email_notification: content.send_broker_email_notification,
            send_email_confirmation: content.send_email_confirmation,
            broker_emails: content.broker_emails,
            fixed_fields: {
              amount: content.fixed_fields.amount_field_type !== 'CUSTOM_USER_INPUT' ? content.amount : null,
              is_stock_enabled: content.is_stock_enabled,
              available_stock_quantity: content.available_stock_quantity,
              amount_field_type: content.fixed_fields.amount_field_type,
              minimum_amount: content.fixed_fields.amount_field_type === 'CUSTOM_USER_INPUT' ? content.fixed_fields.minimum_amount : null,
              maximum_amount: content.fixed_fields.amount_field_type === 'CUSTOM_USER_INPUT' ? content.fixed_fields.maximum_amount : null,
              minimum_quantity: content.fixed_fields.amount_field_type === 'FIXED_WITH_QUANTITY' ? content.fixed_fields.minimum_quantity : null,
              maximum_quantity: content.fixed_fields.amount_field_type === 'FIXED_WITH_QUANTITY' ? content.fixed_fields.maximum_quantity : null
            },
            custom_fields: content.custom_fields
          },
          branding: {
            brand_primary_color: content.merchant_details.brand_primary_color,
            brand_secondary_color: content.merchant_details.brand_secondary_color,
            is_dark_mode: content.is_dark_mode,
            pay_button_text: content.fixed_fields.pay_button_text
          },
          merchant_details: {
            logo: content.merchant_details.logo,
          },
        }
      })
      .catch((error) => {
        this.$notify({
            type: 'error',
            text: error.message,
            closeOnClick: true,
          });
      })
    }
  },

  methods: {
    async tabClicked(tab) {
      if(this.activeTab === 'Page Details') {
        if(!this.validatePageDetails()) {
          return false;
        }
        if(tab === 'Branding') {
          if(!this.validateCustomizePageDetails()) {
            return false;
          }
        }
      } else if(this.activeTab === 'Customise Payment Details') {
        if(tab === 'Branding') {
          if(! await this.validateCustomizePageDetails()) {
            return false;
          }
        }
      }
      this.activeTab = tab;
    },
    checkCustomUrl(showAvailableAlert = true) {
      this.$v.form.customizePageDetails.page_ref.$touch();

      if(this.$v.form.customizePageDetails.page_ref.$anyError) {
        return false;
      }

      if(!this.isCustomUrl || this.isEdit) {
        return true;
      }

      return this.$store.dispatch(`paymentPages/${CHECK_PAYMENT_PAGE_CUTOM_URL_AVAILABLITY}`, this.form.customizePageDetails.page_ref)
        .then((response) => {
          if(showAvailableAlert) {
            this.$notify({
              type: 'success',
              text: response.message,
              closeOnClick: true,
            });
          }
          return true;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            text: error.message,
            closeOnClick: true,
          });
          return false;
        });
    },
    changeCurrency(value) {
      this.form.pageDetails.currency = value;
    },
    changeCountry(value) {
      this.form.pageDetails.country_code = value;
    },
    storePaymentMethods(value) {
      this.selectedPaymentMethods = value;
    },
    uploadFile(event) {
      let fileList = event.target.files;

      if(!fileList) {
        fileList = event.dataTransfer.items;
      }

      if (!fileList.length) return;

      if (!Common.fileValdation(fileList[0], 1)) {
        this.$refs.logoUpload.reset();
        return;
      }

      const formData = new FormData();
      formData.append('file', fileList[0], fileList[0].name);

      this.$store.dispatch(`paymentPages/${UPLOAD_PAGE_IMAGE_IN_STORE}`, formData)
      .then((response) => {
        this.form.merchant_details.logo = response.link;
      })
      .catch((error) => {
        console.log(error);
        this.$notify({
          type: 'error',
          text: error.status_message,
          closeOnClick: true,
        });
      });
    },
    validatePageDetails() {
      this.$v.form.pageDetails.$touch();

      if(this.$v.form.pageDetails.$anyError) {
        return false;
      }

      return true;
    },
    async validateCustomizePageDetails() {
      this.$v.form.customizePageDetails.$touch();

      if(this.$v.form.customizePageDetails.$anyError) {
        return false;
      }

      let validCustomUrl = await this.checkCustomUrl(false);

      if(!validCustomUrl) {
        return false;
      }

      return true;
    },
    validateBranding() {
      this.$v.form.branding.$touch();

      if(this.$v.form.branding.$anyError) {
        return false;
      }

      return true;
    },
    validateAmountField() {
      this.$v.form.customizePageDetails.fixed_fields.$touch();

      if(this.$v.form.customizePageDetails.fixed_fields.$anyError) {
        return false;
      }

      return true;
    },
    validateCustomFieldState(name, index) {
      const { $dirty, $error } = this.$v.form.customizePageDetails.custom_fields.fields.$each[index][name];
      return $dirty ? !$error : null;
    },
    validateCustomField(index) {
      this.$v.form.customizePageDetails.custom_fields.fields.$each[index].$touch();

      if(this.$v.form.customizePageDetails.custom_fields.fields.$each[index].$anyError) {
        return false;
      }

      return true;
    },
    async goToNext() {
      if(this.activeTab == 'Page Details') {

        if(!this.validatePageDetails()) {
          return false;
        }

        this.activeTab = 'Customise Payment Details';
      } else {
        if(! await this.validateCustomizePageDetails()) {
          return false;
        }
        this.activeTab = 'Branding';
      }
    },
    goPrevious() {
      if(this.activeTab == 'Branding') {
        this.activeTab = 'Customise Payment Details';
      } else {
        this.activeTab = 'Page Details';
      }
    },
    validatePageDetailsState(name) {
      const { $dirty, $error } = this.$v.form.pageDetails[name];
      return $dirty ? !$error : null;
    },
    validateFixedFieldsState(name) {
      const { $dirty, $error } = this.$v.form.customizePageDetails.fixed_fields[name];
      return $dirty ? !$error : null;
    },
    addEmailToBrokerNotification() {
      this.$v.brokerNotificationEmail.$touch();
      if(this.$v.brokerNotificationEmail.$anyError || !this.form.customizePageDetails.send_broker_email_notification) {
        return false;
      }

      if(this.form.customizePageDetails.broker_emails && this.form.customizePageDetails.broker_emails.includes(this.brokerNotificationEmail)) {
        this.$notify({
          type: 'error',
          text: 'This email already exist',
          closeOnClick: true,
        });
        return false;
      }
      this.form.customizePageDetails.broker_emails.push(this.brokerNotificationEmail);
      this.brokerNotificationEmail = "";
      this.$v.brokerNotificationEmail.$reset();
    },
    removeEmailFromBrokerNotification(index) {
      this.form.customizePageDetails.broker_emails.splice(index, 1); 
    },
    showToast(toastId) {
      this.$bvToast.show(toastId);
      this.toastOpen = true;
    },
    hideToast(toastId) {
      // if(toastId.startsWith("custom-field-")) {
      //   const index = Number(toastId.split('-')[2]);
      //   const isDuplicate = this.isDuplicateTitle(index);
      //   if(isDuplicate) {
      //     this.duplicateCustomFields.push(index);
      //     return false;
      //   } else {
      //     this.duplicateCustomFields = this.duplicateCustomFields.filter(key => key !== index);
      //   }
      // }

      this.$bvToast.hide(toastId);
      this.toastOpen = false;
    },
    changeAmountFieldType(type) {
      if(type == 'FIXED') {
        this.form.customizePageDetails.fixed_fields.minimum_amount = null;
        this.form.customizePageDetails.fixed_fields.maximum_amount = null;

        this.form.customizePageDetails.fixed_fields.minimum_quantity = null;
        this.form.customizePageDetails.fixed_fields.maximum_quantity = null;
      } else if(type == 'FIXED_WITH_QUANTITY') {
        this.form.customizePageDetails.fixed_fields.minimum_amount = null;
        this.form.customizePageDetails.fixed_fields.maximum_amount = null;
      } else if(type == 'CUSTOM_USER_INPUT') {
        this.form.customizePageDetails.fixed_fields.amount = null;
        this.form.customizePageDetails.fixed_fields.minimum_quantity = null;
        this.form.customizePageDetails.fixed_fields.maximum_quantity = null;
      }

      this.toggleAmountConfigTabs(type);
      this.form.customizePageDetails.fixed_fields.amount_field_type = type;
    },
    toggleAmountConfigTabs(tab) {
      if(tab === this.currentActiveAmountConfig) {
        this.amountConfigActive = !this.amountConfigActive;
      } else {
        this.amountConfigActive = true;
        this.currentActiveAmountConfig = tab;
      }
    },
    removeCustomField(index) {
      if(this.form.customizePageDetails.custom_fields.fields[index].key) {
        this.form.customizePageDetails.custom_fields.fields[index].is_active = false;
      } else {
        this.form.customizePageDetails.custom_fields.fields.splice(index,1);
      }
    },
    typeChange(index, field) {
      this.form.customizePageDetails.custom_fields.fields[index].type = field;
    },
    async addNewCustomField() {
      let order = -1;

      this.form.customizePageDetails.custom_fields.fields.map((el) => order = Math.max(el.sort_order, order));
      await this.form.customizePageDetails.custom_fields.fields.push({
        type: "text",
        is_mandatory: false,
        key: "",
        title: "",
        tooltip: "",
        description: "",
        sort_order: order + 1,
        is_active: true,
        drop_down_options: {
          options: []
        },
        option_text: ""
      });

      this.showToast(`custom-field-${this.form.customizePageDetails.custom_fields.fields.length - 1}-toast`);
    },
    addOption(e, index) {
      this.$v.form.customizePageDetails.custom_fields.fields.$each[index].option_text.$touch();

      if(this.$v.form.customizePageDetails.custom_fields.fields.$each[index].option_text.$anyError) {
        return false;
      }

      this.form.customizePageDetails.custom_fields.fields[index].drop_down_options.options.push({
        name: e.target.value,
        value: e.target.value
      });
      this.form.customizePageDetails.custom_fields.fields[index].option_text = '';
    },
    removeOption(key, index) {
      this.form.customizePageDetails.custom_fields.fields[index].drop_down_options.options.splice(key, 1);
    },
    removeLogo() {
      this.form.merchant_details.logo = "";
    },
    saveChanges() {
      if(!this.validatePageDetails()) {
        this.activeTab = "Page Details";
        return false;
      }
      if(!this.validateCustomizePageDetails()) {
        this.activeTab = "Customise Payment Details";
        return false;
      }
      if(!this.validateBranding()) {
        this.activeTab = "Branding";
        return false;
      }

      this.$store.dispatch(`paymentPages/${this.isEdit ? UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE : CREATE_PAYMENT_PAGE_RECORD_IN_STORE}`, this.payloadForPaymentPage)
        .then((response) => {
          this.$router.push({ path: '/settings/payment-pages-details', query: { pageRef: this.isEdit ? this.$route.query.pageRef : response.payment_page_ref }})
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: 'error',
            text: error.message,
            closeOnClick: true,
          });
        })
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },

  directives: {
    'click-outside': {
      bind (el, binding, vnode) {
        const handleOutsideClick = (e) => {
          e.stopPropagation()
          const { handler, exclude, toastId, index } = binding.value
          let clickedOnExcludedEl = false
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[exclude]
            if(Array.isArray(excludedEl)) {
              clickedOnExcludedEl = !excludedEl[0] || excludedEl[0].contains(e.target)
            } else {
              clickedOnExcludedEl = !excludedEl || excludedEl.contains(e.target)
            }
          }
          
          if (!el.contains(e.target) && !clickedOnExcludedEl) {
            if(toastId === "amount-toast") {
              if(vnode.context.validateAmountField()) {
                vnode.context[handler](toastId)
                vnode.context.amountConfigActive = false;
              }
            } else {
              if(vnode.context.validateCustomField(index)) {
                vnode.context[handler](toastId)
              }
            }
          }
        }
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
      },
    }
  }
};
</script>

<template>
  <Layout>
    <div class="d-flex justify-content-between mb-3">
        <Breadcrumb :items="items" :goBackTo="{ name: 'Payment Pages' }" />
        <b-btn variant="primary" @click="saveChanges">{{isEdit ? `Update` : `Create`}} and Publish Page</b-btn>
    </div>

    <div class="menu-content">
        <div class="tab-content-section">
          <b-btn :variant="activeTab === 'Page Details' ? 'primary' : 'white'" @click="tabClicked('Page Details')" class="tab-button" :class="{'tab-active': activeTab === 'Page Details'}">Page Details</b-btn>
          <b-btn :variant="activeTab === 'Customise Payment Details' ? 'primary' : 'white'" @click="tabClicked('Customise Payment Details')" class="tab-button" :class="{'tab-active': activeTab === 'Customise Payment Details'}">Customise Payment Details</b-btn>
          <b-btn :variant="activeTab === 'Branding' ? 'primary' : 'white'" @click="tabClicked('Branding')" class="tab-button" :class="{'tab-active': activeTab === 'Branding'}">Branding</b-btn>
        </div>
    </div>

    <hr style="border-color: #DCDAD5;" class="mb-0">

    <div class="d-flex">
      <div class="mt-4 pr-4" style="width: 43%;">
        <div v-if="activeTab === 'Page Details'">
          <!-- logo upload -->
          <div class="d-flex" style="margin-bottom: 32px;">
            <input type="file" ref="imageUpload" id="imageUpload" style="opacity: 0; position: absolute; height: 60px; width: 60px; z-index: 1; cursor: pointer;"  accept=".png, .jpg, .jpeg, .svg" @change="uploadFile">
            <div class="d-flex" style="border-radius: 12px; border: 1px solid rgba(220, 218, 213, 0.50); background: #FFF; box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.30); width: 60px; height: 60px; gap: 3.5px;">
              <!-- logo preview -->
              <img v-if="form.merchant_details.logo" :src="form.merchant_details.logo" alt="logo" style="max-width: 60px; max-height: 60px;" class="m-auto" />
              <div v-else class="d-flex justify-content-center flex-column flex-fill">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="mx-auto my-0">
                  <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" stroke="#DCDAD5" stroke-linejoin="round" stroke-dasharray="2 2"/>
                  <path d="M4.5 9H13.5M9 13.5V4.5" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
  
                <p style="line-height: 16px; font-size: 8px;" class="mx-auto my-0">Your Logo</p>
              </div>
            </div>
            <!-- logo upload end -->

            <!-- brand name -->
            <h1 class="my-auto" style="line-height: 24px; font-size: 16px; font-weight: 400; margin-left: 12px; color: #000;">{{ brandName }}</h1>
          </div>

          <div class="mb-3">
            <b-input
              v-model="form.pageDetails.title"
              placeholder="Enter Page Title Here"
              :state="validatePageDetailsState('title')"
              style="border-radius: 12px; border: 1px solid #DCDAD5; height: 44px; border-radius: 12px; border: 1px solid #DCDAD5; font-size: 14px; font-weight: 400; line-height: 24px;"
            ></b-input>
            <b-form-invalid-feedback :state="validatePageDetailsState('title')">
              Page Title is required.
            </b-form-invalid-feedback>
          </div>

          <div class="mb-3">
            <VueEditor
              id="description"
              v-model="form.pageDetails.description"
              :placeholder="$t('views.payment_pages.placeholders.description')"
              :editorToolbar="customToolbar"
              :class="{'border border-danger': $v.form.pageDetails['description'].$dirty && $v.form.pageDetails['description'].$error}"
            />
            <b-form-invalid-feedback :state="validatePageDetailsState('description')">
              Page Description is required.
            </b-form-invalid-feedback>
          </div>

          <div class="d-flex justify-content-between">
            <div style="width: 37%;">
              <label for="currency" style="font-size: 14px; font-weight: 350; line-height: 20px;">Currency</label>
              <Currency :currency="form.pageDetails.currency" @currency-changed="changeCurrency" :class="{'border border-danger': $v.form.pageDetails['currency'].$dirty && $v.form.pageDetails['currency'].$error}" />
              <b-form-invalid-feedback :state="validatePageDetailsState('currency')">
                Currency is required.
              </b-form-invalid-feedback>
            </div>
            <div style="width: 61%;">
              <label for="country_code" style="font-size: 14px; font-weight: 350; line-height: 20px;">Buyer Country</label>
              <Country :country="form.pageDetails.country_code" @country-changed="changeCountry" :class="{'border border-danger': $v.form.pageDetails['country_code'].$dirty && $v.form.pageDetails['country_code'].$error}" />
              <b-form-invalid-feedback :state="validatePageDetailsState('country_code')">
                Buyer Country is required.
              </b-form-invalid-feedback>
            </div>
          </div>

          <hr class="my-4" style="border-color: rgba(220, 218, 213, 0.30);">

          <PaymentMethodModal @payment-methods="storePaymentMethods" :isEdit="isEdit" :selectedPaymentMethods="paymentMethods" :currency="form.pageDetails.currency" />

          <hr class="my-4" style="border-color: rgba(220, 218, 213, 0.30);">

          <b-btn class="text-chai p-0" variant="link" @click="() => form.pageDetails.show_social_media_options = !form.pageDetails.show_social_media_options">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5 10H15M10 15V5" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ form.pageDetails.show_social_media_options ? `Remove social media share icons` : `Add social media share icons` }}
          </b-btn>

          <hr class="my-4" style="border-color: rgba(220, 218, 213, 0.30);">

          <!-- contact us -->
          <label class="mb-3" style="font-size: 14px; font-weight: 400; line-height: 20px;">Contact Us:</label>

          <!-- support phone number -->
          <div class="mb-2">
            <div class="d-flex">
              <div class="rounded-circle text-center" style="background-color: #FC6B2D; height: 24px; width: 24px; border-radius: 12px; margin-right: 12px;">
                <svg style="margin-top: 5px;" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.6081 10.5606C12.6081 10.7631 12.5631 10.9712 12.4675 11.1737C12.3719 11.3762 12.2481 11.5675 12.085 11.7475C11.8094 12.0512 11.5056 12.2706 11.1625 12.4112C10.825 12.5519 10.4594 12.625 10.0656 12.625C9.49188 12.625 8.87875 12.49 8.23187 12.2144C7.585 11.9387 6.93812 11.5675 6.29688 11.1006C5.65 10.6281 5.03687 10.105 4.45187 9.52562C3.8725 8.94062 3.34937 8.3275 2.8825 7.68625C2.42125 7.045 2.05 6.40375 1.78 5.76812C1.51 5.12687 1.375 4.51375 1.375 3.92875C1.375 3.54625 1.4425 3.18062 1.5775 2.84312C1.7125 2.5 1.92625 2.185 2.22437 1.90375C2.58437 1.54938 2.97813 1.375 3.39438 1.375C3.55188 1.375 3.70938 1.40875 3.85 1.47625C3.99625 1.54375 4.12563 1.645 4.22688 1.79125L5.53188 3.63062C5.63313 3.77125 5.70625 3.90062 5.75687 4.02437C5.8075 4.1425 5.83563 4.26062 5.83563 4.3675C5.83563 4.5025 5.79625 4.6375 5.7175 4.76687C5.64438 4.89625 5.5375 5.03125 5.4025 5.16625L4.975 5.61062C4.91312 5.6725 4.885 5.74562 4.885 5.83562C4.885 5.88062 4.89063 5.92 4.90188 5.965C4.91875 6.01 4.93563 6.04375 4.94688 6.0775C5.04813 6.26312 5.2225 6.505 5.47 6.7975C5.72312 7.09 5.99312 7.38812 6.28562 7.68625C6.58937 7.98437 6.88187 8.26 7.18 8.51312C7.4725 8.76062 7.71437 8.92937 7.90562 9.03062C7.93375 9.04187 7.9675 9.05875 8.00687 9.07562C8.05187 9.0925 8.09687 9.09812 8.1475 9.09812C8.24312 9.09812 8.31625 9.06437 8.37812 9.0025L8.80563 8.58062C8.94625 8.44 9.08125 8.33312 9.21063 8.26562C9.34 8.18687 9.46937 8.1475 9.61 8.1475C9.71687 8.1475 9.82938 8.17 9.95312 8.22062C10.0769 8.27125 10.2063 8.34437 10.3469 8.44L12.2088 9.76187C12.355 9.86312 12.4562 9.98125 12.5181 10.1219C12.5744 10.2625 12.6081 10.4031 12.6081 10.5606Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
                </svg>
              </div>
              <b-input class="my-auto" type="text" v-model="form.pageDetails.phone_number" :state="validatePageDetailsState('phone_number')" placeholder="Enter support number" autocomplete="off" style="font-size: 14px; line-height: 20px; border: none; outline: none; width: 100%; font-weight: 350; padding-top: 1px; padding-bottom: 1px; height: auto;" />
            </div>
            <b-form-invalid-feedback style="margin-left: 36px;" :state="validatePageDetailsState('phone_number')">
              Please enter valid Phone Number.
            </b-form-invalid-feedback>
          </div>

          <!-- support email -->
          <div>
            <div class="d-flex">
              <div class="rounded-circle text-center" style="background-color: #FC6B2D; height: 24px; width: 24px; border-radius: 12px; margin-right: 12px;">
                <svg style="margin-top: 5px;" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.8125 11.7813H4.1875C2.5 11.7813 1.375 10.9375 1.375 8.96875V5.03125C1.375 3.0625 2.5 2.21875 4.1875 2.21875H9.8125C11.5 2.21875 12.625 3.0625 12.625 5.03125V8.96875C12.625 10.9375 11.5 11.7813 9.8125 11.7813Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.8125 5.3125L8.05187 6.71875C7.4725 7.18 6.52187 7.18 5.9425 6.71875L4.1875 5.3125" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <b-input class="my-auto" type="email" v-model="form.pageDetails.email_address" :state="validatePageDetailsState('email_address')" autocomplete="off" placeholder="Enter support email" style="font-size: 14px; line-height: 20px; border: none; outline: none; width: 100%; font-weight: 350; padding-top: 1px; padding-bottom: 1px; height: auto;" />
            </div>
            <b-form-invalid-feedback style="margin-left: 36px;" :state="validatePageDetailsState('email_address')">
              Please enter valid Email.
            </b-form-invalid-feedback>
          </div>

          <hr class="my-4" style="border-color: rgba(220, 218, 213, 0.30);">

          <b-btn class="text-chai p-0 mb-3" variant="link" @click="() => showTermsAndConditions = !showTermsAndConditions">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5 10H15M10 15V5" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Add Your Terms & Conditions
          </b-btn>

          <VueEditor
            v-if="showTermsAndConditions"
            id="terms_and_conditions"
            name="terms_and_conditions"
            :placeholder="$t('views.payment_pages.placeholders.terms_conditions')"
            :editorToolbar="customToolbar"
            v-model="form.pageDetails.terms_and_conditions"
            class="mb-3"
          />
          <div class="font-14px" style="color: rgba(0, 0, 0, 0.50); font-weight: 325; line-height: 20px;">
            You agree to share information entered on this page with {{ userName }} (owner of this page) and PortOne, adhering to applicable laws.
          </div>

          <b-btn class="mt-4 float-right px-3 py-2" @click="goToNext" style="font-size: 14px; font-weight: 400; line-height: 20px;" variant="primary">
            Next
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M12.0248 4.94141L17.0832 9.99974L12.0248 15.0581M2.9165 9.99974H16.9415" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </b-btn>
        </div>

        <div v-if="activeTab === 'Customise Payment Details'">
          <!-- page details customization -->
          <div>
            <h1 class="my-auto" style="line-height: 24px; font-size: 18px; font-weight: 400; color: #000;">Payment Details</h1>

            <hr style="border-color: rgba(220, 218, 213, 0.30); margin-top: 18px; margin-bottom: 29px;">

            <!-- amount field with config options -->
            <div class="my-3 show-border pr-3" style="padding-left: 32px;">
              <label for="amount" style="font-size: 14px; font-weight: 350; line-height: 20px;">Amount</label>
              <div class="d-flex">
                <!-- amount field view and edit placeholder -->
                <b-input type="number" v-model.number="form.customizePageDetails.fixed_fields.amount" :state="validateFixedFieldsState('amount')" @mousewheel.prevent placeholder="0" min="0" name="amount" style="border-radius: 12px; border: 1px solid #DCDAD5; height: 44px; max-width: 350px;"/>

                <!-- edit button for amount field configuration -->
                <b-btn size="sm" variant="light" class="show-edit my-auto" @click="showToast('amount-toast')" style="border-radius: 4px; background: rgba(220, 218, 213, 0.30); padding: 1.5px 5px; border: none; margin-left: 13px;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g opacity="0.3">
                      <path d="M7.36672 1.99969L2.80561 6.82746C2.63339 7.0108 2.46672 7.37191 2.43339 7.62191L2.22783 9.42191C2.15561 10.0719 2.62228 10.5164 3.26672 10.4052L5.05561 10.0997C5.30561 10.0552 5.65561 9.87191 5.82783 9.68302L10.3889 4.85524C11.1778 4.02191 11.5334 3.07191 10.3056 1.9108C9.08338 0.760798 8.15561 1.16635 7.36672 1.99969Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.60559 2.80566C6.84448 4.339 8.08892 5.51122 9.63337 5.66678" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1.66663 12.2227H11.6666" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                  </svg>
                </b-btn>
              </div>
            </div>

            <div class="modal-backdrop" style="z-index: 5;" v-if="toastOpen"></div>
            <!-- amount field configuration -->
            <b-toast
              id="amount-toast"
              no-close-button
              static
              v-click-outside="{
                exclude: 'amount-toast',
                handler: 'hideToast',
                toastId: 'amount-toast',
                index: null
              }"
              backdrop-filter
              no-auto-hide
              style="position: absolute; z-index: 6; border-radius: 12px; border: 1px solid rgba(220, 218, 213, 0.30); background: #FFF; box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.30); width: 365px;"
              class="bg-light ml-5"
              body-class="p-0"
              >
              <div ref="amount-toast" class="py-3">
                <h3 class="font-14px mb-3 px-3" style="color: #000; font-weight: 400; line-height: 20px;">Select Amount Type</h3>

                <hr class="my-0 mx-3" style="border-color: rgba(220, 218, 213, 0.30);">

                <div class="accordion" role="tablist">
                  <!-- fixed amount field configuration -->
                  <div>
                    <div class="d-flex justify-content-between py-2 px-3" :style="{backgroundColor: form.customizePageDetails.fixed_fields.amount_field_type == 'FIXED' ? '#F5F4F2' : '#FFF'}" @click="changeAmountFieldType('FIXED')" v-b-toggle.accordion-1 role="tab">
                      <div class="d-flex">
                        <div class="d-flex" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30); width: 36px; height: 36px; margin-right: 24px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="m-auto">
                            <path d="M9 9C11.0711 9 12.75 7.32107 12.75 5.25C12.75 3.17893 11.0711 1.5 9 1.5C6.92893 1.5 5.25 3.17893 5.25 5.25C5.25 7.32107 6.92893 9 9 9Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.5575 16.5C2.5575 13.5975 5.44499 11.25 8.99999 11.25C9.71999 11.25 10.4175 11.3475 11.07 11.5275" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.5 13.5C16.5 14.0625 16.3425 14.595 16.065 15.045C15.9075 15.315 15.705 15.555 15.4725 15.75C14.9475 16.2225 14.2575 16.5 13.5 16.5C12.405 16.5 11.4525 15.915 10.935 15.045C10.6575 14.595 10.5 14.0625 10.5 13.5C10.5 12.555 10.935 11.7075 11.625 11.16C12.1425 10.7475 12.795 10.5 13.5 10.5C15.1575 10.5 16.5 11.8425 16.5 13.5Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.33 13.4996L13.0724 14.2421L14.67 12.7646" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <label class="my-auto">Fixed Amount</label>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="my-auto" :style="{transform: amountConfigActive && currentActiveAmountConfig === 'FIXED' ? 'rotate(90deg)' : 'rotate(0deg)'}">
                        <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" fill="#DCDAD5" fill-opacity="0.3"/>
                        <path d="M8.05505 11.6475L10.6951 9.00004L8.05505 6.35254" fill="#DCDAD5" fill-opacity="0.3"/>
                        <path d="M8.05505 11.6475L10.6951 9.00004L8.05505 6.35254" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <!-- element to collapse -->
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" style="background: rgba(220, 218, 213, 0.10);">
                      <div class="pt-2 px-3" style="padding-bottom: 12px;">
                        <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.50);" for="amount">Amount</label>
                        <b-input type="number" v-model.number="form.customizePageDetails.fixed_fields.amount" :state="validateFixedFieldsState('amount')" @mousewheel.prevent placeholder="0" class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                      </div>
                    </b-collapse>
                  </div>

                  <hr class="my-0 mx-3" style="border-color: rgba(220, 218, 213, 0.30);">

                  <!-- fixed amount field with QTY configuration -->
                  <div>
                    <div class="d-flex justify-content-between py-2 px-3" :style="{backgroundColor: form.customizePageDetails.fixed_fields.amount_field_type == 'FIXED_WITH_QUANTITY' ? '#F5F4F2' : '#FFF'}" @click="changeAmountFieldType('FIXED_WITH_QUANTITY')" v-b-toggle.accordion-2 role="tab">
                      <div class="d-flex">
                        <div class="d-flex" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30); width: 36px; height: 36px; margin-right: 24px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="m-auto">
                            <path d="M9 9C11.0711 9 12.75 7.32107 12.75 5.25C12.75 3.17893 11.0711 1.5 9 1.5C6.92893 1.5 5.25 3.17893 5.25 5.25C5.25 7.32107 6.92893 9 9 9Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.5575 16.5C2.5575 13.5975 5.44499 11.25 8.99999 11.25C9.71999 11.25 10.4175 11.3475 11.07 11.5275" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.5 13.5C16.5 13.74 16.47 13.9725 16.41 14.1975C16.3425 14.4975 16.2225 14.79 16.065 15.045C15.5475 15.915 14.595 16.5 13.5 16.5C12.7275 16.5 12.03 16.2075 11.505 15.7275C11.28 15.5325 11.085 15.3 10.935 15.045C10.6575 14.595 10.5 14.0625 10.5 13.5C10.5 12.69 10.8225 11.9475 11.3475 11.4075C11.895 10.845 12.66 10.5 13.5 10.5C14.385 10.5 15.1875 10.8825 15.7275 11.4975C16.2075 12.03 16.5 12.735 16.5 13.5Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.6176 13.4844H12.3826" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.5 12.3896V14.6321" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <label class="my-auto">Fixed Amount with Quantity</label>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="my-auto" :style="{transform: amountConfigActive && currentActiveAmountConfig === 'FIXED_WITH_QUANTITY' ? 'rotate(90deg)' : 'rotate(0deg)'}">
                        <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" fill="#DCDAD5" fill-opacity="0.3"/>
                        <path d="M8.05505 11.6475L10.6951 9.00004L8.05505 6.35254" fill="#DCDAD5" fill-opacity="0.3"/>
                        <path d="M8.05505 11.6475L10.6951 9.00004L8.05505 6.35254" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <!-- element to collapse -->
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" style="background: rgba(220, 218, 213, 0.10);">
                      <div class="pt-2 px-3" style="padding-bottom: 12px;">
                        <div class="mb-2">
                          <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.50);" for="amount">Amount</label>
                          <b-input type="number" v-model.number="form.customizePageDetails.fixed_fields.amount" :state="validateFixedFieldsState('amount')" @mousewheel.prevent placeholder="0" class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                        </div>
                        <div class="d-flex justify-content-between" style="gap: 17px;">
                          <div>
                            <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.50);" for="min_qty">Min. Quantity</label>
                            <b-input type="number" v-model.number="form.customizePageDetails.fixed_fields.minimum_quantity" :state="validateFixedFieldsState('minimum_quantity')" @mousewheel.prevent placeholder="0" class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                          </div>
                          <div>
                            <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.50);" for="max_qty">Max. Quantity</label>
                            <b-input type="number" v-model.number="form.customizePageDetails.fixed_fields.maximum_quantity" :state="validateFixedFieldsState('maximum_quantity')" @mousewheel.prevent placeholder="0" class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <hr class="my-0 mx-3" style="border-color: rgba(220, 218, 213, 0.30);">

                  <!-- custom amount field configuration -->
                  <div>
                    <div class="d-flex justify-content-between py-2 px-3" :style="{backgroundColor: form.customizePageDetails.fixed_fields.amount_field_type == 'CUSTOM_USER_INPUT' ? '#F5F4F2' : '#FFF'}" @click="changeAmountFieldType('CUSTOM_USER_INPUT')" v-b-toggle.accordion-3 role="tab">
                      <div class="d-flex">
                        <div class="d-flex" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30); width: 36px; height: 36px; margin-right: 24px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="m-auto">
                            <path d="M9 9C11.0711 9 12.75 7.32107 12.75 5.25C12.75 3.17893 11.0711 1.5 9 1.5C6.92893 1.5 5.25 3.17893 5.25 5.25C5.25 7.32107 6.92893 9 9 9Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.4075 11.8048L11.7525 14.4598C11.6475 14.5648 11.55 14.7598 11.5275 14.9023L11.385 15.9148C11.3325 16.2823 11.5875 16.5373 11.955 16.4848L12.9675 16.3423C13.11 16.3198 13.3125 16.2223 13.41 16.1173L16.065 13.4623C16.5225 13.0048 16.74 12.4723 16.065 11.7973C15.3975 11.1298 14.865 11.3473 14.4075 11.8048Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.025 12.1875C14.25 12.9975 14.88 13.6275 15.69 13.8525" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.5575 16.5C2.5575 13.5975 5.44502 11.25 9.00002 11.25C9.78002 11.25 10.53 11.3625 11.2275 11.5725" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <label class="my-auto">Custom Amount</label>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="my-auto" :style="{transform: amountConfigActive && currentActiveAmountConfig === 'CUSTOM_USER_INPUT' ? 'rotate(90deg)' : 'rotate(0deg)'}">
                        <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" fill="#DCDAD5" fill-opacity="0.3"/>
                        <path d="M8.05505 11.6475L10.6951 9.00004L8.05505 6.35254" fill="#DCDAD5" fill-opacity="0.3"/>
                        <path d="M8.05505 11.6475L10.6951 9.00004L8.05505 6.35254" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <!-- element to collapse -->
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" style="background: rgba(220, 218, 213, 0.10);">
                      <div class="pt-2 px-3" style="padding-bottom: 12px;">
                        <div class="d-flex justify-content-between" style="gap: 17px;">
                          <div>
                            <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.50);" for="min_amount">Min. Amount</label>
                            <b-input type="number" v-model.number="form.customizePageDetails.fixed_fields.minimum_amount" placeholder="0" :state="validateFixedFieldsState('minimum_amount')" @mousewheel.prevent class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                          </div>
                          <div>
                            <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.50);" for="max_amount">Max. Amount</label>
                            <b-input type="number" v-model.number="form.customizePageDetails.fixed_fields.maximum_amount" placeholder="0" :state="validateFixedFieldsState('maximum_amount')" @mousewheel.prevent class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>

                  <hr class="my-0 mx-3" style="border-color: rgba(220, 218, 213, 0.30);">

                  <!-- stock configuration -->
                  <div>
                    <div class="d-flex justify-content-between py-2 px-3" :style="{backgroundColor: amountConfigActive && currentActiveAmountConfig == 'SET_UNITS' ? '#F5F4F2' : '#FFF'}" @click="toggleAmountConfigTabs('SET_UNITS')" v-b-toggle.accordion-4 role="tab">
                      <div class="d-flex">
                        <div class="d-flex" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30); width: 36px; height: 36px; margin-right: 24px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="m-auto">
                            <path d="M16.5 8.325V5.175C16.5 2.55 15.45 1.5 12.825 1.5H9.675C7.05 1.5 6 2.55 6 5.175V6H8.325C10.95 6 12 7.05 12 9.675V12H12.825C15.45 12 16.5 10.95 16.5 8.325Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 12.825V9.675C12 7.05 10.95 6 8.325 6H5.175C2.55 6 1.5 7.05 1.5 9.675V12.825C1.5 15.45 2.55 16.5 5.175 16.5H8.325C10.95 16.5 12 15.45 12 12.825Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.56006 11.2496L6.02256 12.7121L8.94006 9.78711" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <label class="my-auto">Set Units</label>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" class="my-auto" :style="{transform: amountConfigActive && currentActiveAmountConfig === 'SET_UNITS' ? 'rotate(90deg)' : 'rotate(0deg)'}">
                        <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" fill="#DCDAD5" fill-opacity="0.3"/>
                        <path d="M8.05505 11.6475L10.6951 9.00004L8.05505 6.35254" fill="#DCDAD5" fill-opacity="0.3"/>
                        <path d="M8.05505 11.6475L10.6951 9.00004L8.05505 6.35254" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <!-- element to collapse -->
                    <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" style="background: rgba(220, 218, 213, 0.10);">
                      <div class="pt-2 px-3" style="padding-bottom: 12px;">
                        <h3 class="font-12px mt-1 mb-2" style="font-weight: 350; line-height: 16px;">Units Available <span style="font-size: 10px; font-weight: 325;">(in stock)</span></h3>

                        <b-form-group v-slot="{ ariaDescribedby }" style="margin-bottom: 2px;" :state="validateFixedFieldsState('is_stock_enabled')">
                          <b-form-radio v-model="form.customizePageDetails.fixed_fields.is_stock_enabled" :aria-describedby="ariaDescribedby" name="some-radios" class="font-12px" style="line-height: 16px; color: rgba(0, 0, 0, 0.50); margin-bottom: 12px;" :value="false">Unlimited</b-form-radio>
                          <b-form-radio v-model="form.customizePageDetails.fixed_fields.is_stock_enabled" :aria-describedby="ariaDescribedby" name="some-radios" class="font-12px" style="line-height: 16px; color: rgba(0, 0, 0, 0.50);" :value="true">Limited</b-form-radio>
                        </b-form-group>
                        <b-input type="number" v-if="form.customizePageDetails.fixed_fields.is_stock_enabled" v-model.number="form.customizePageDetails.fixed_fields.available_stock_quantity" :state="validateFixedFieldsState('available_stock_quantity')" @mousewheel.prevent class="font-14px w-50" min="0" placeholder="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px; margin-left: 24px;"></b-input>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </b-toast>

            <!-- custom fields -->
            <draggable
              :list="form.customizePageDetails.custom_fields.fields"
              class="list-group"
              handle=".move-custom-field"
            >
              <div v-for="(field, index) in form.customizePageDetails.custom_fields.fields" v-show="field.is_active" :key="index">
                <div class="my-3 show-border pr-3" style="padding-left: 32px;">
                  <div class="d-flex">
                    <div class="flex-fill my-auto">
                      <label v-if="field.type !== 'checkbox'" style="font-size: 14px; font-weight: 350; line-height: 20px; word-wrap: break-word; max-width: 300px;">{{ field.title }} <span style="color: rgba(0, 0, 0, 0.50); font-weight: 325;" v-show="!field.is_mandatory">(optional)</span></label>
                      <div class="d-flex">
                        <!-- custom field input view and edit placeholder -->
                        <span class="move-custom-field show-edit" style="margin-left: -20px; margin-right: 12px; cursor: move;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                            <ellipse cx="1.28875" cy="1.2" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                            <ellipse cx="6.44375" cy="1.2" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                            <ellipse cx="1.28875" cy="5.9998" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                            <ellipse cx="6.44375" cy="5.9998" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                            <ellipse cx="1.28875" cy="10.7996" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                            <ellipse cx="6.44375" cy="10.7996" rx="1.28875" ry="1.2" fill="#DCDAD5"/>
                          </svg>
                        </span>
    
                        <div class="d-flex w-100" v-if="field.type === 'datepicker' || field.type === 'dropdown'" style="border-radius: 12px; border: 1px solid #DCDAD5; background: #FFF; padding: 6px; max-width: 350px !important;">
                          <b-input v-model="field.description" class="flex-fill" style="border: none; color: rgba(0, 0, 0, 0.30);"></b-input>
                          <div class="d-flex" style="border-radius: var(--8, 8px); opacity: 0.3; background: #DCDAD5; height: 32px; width: 32px;">
                            <svg v-if="field.type === 'datepicker'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="m-auto">
                              <g opacity="0.5" clip-path="url(#clip0_6843_40677)">
                                <path d="M5.44207 1.60059V3.52059M10.5621 1.60059V3.52059M2.56207 6.13819H13.4421M13.7621 5.76059V11.2006C13.7621 13.1206 12.8021 14.4006 10.5621 14.4006H5.44207C3.20207 14.4006 2.24207 13.1206 2.24207 11.2006V5.76059C2.24207 3.84059 3.20207 2.56059 5.44207 2.56059H10.5621C12.8021 2.56059 13.7621 3.84059 13.7621 5.76059Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.3668 9.08789H10.3725M10.3668 11.0079H10.3725M7.99877 9.08789H8.00517M7.99877 11.0079H8.00517M5.63013 9.08789H5.63653M5.63013 11.0079H5.63653" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_6843_40677">
                                  <rect width="15.36" height="15.36" fill="white" transform="translate(0.322021 0.320312)"/>
                                </clipPath>
                              </defs>
                            </svg>
                            <svg v-else-if="field.type === 'dropdown'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="m-auto">
                              <g opacity="0.5">
                                <path d="M13.0708 6.04785L8.89803 10.2207C8.40523 10.7135 7.59883 10.7135 7.10603 10.2207L2.93323 6.04785" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <b-form-checkbox
                          v-if="field.type === 'checkbox'"
                          class="preview-custom-checkbox"
                          disabled
                        >
                          {{ field.title }}
                        </b-form-checkbox>
                        <b-input v-else type="text" v-model="field.description" style="border-radius: 12px; border: 1px solid #DCDAD5; height: 44px; max-width: 350px; color: rgba(0, 0, 0, 0.30);"/>
                      </div>
                    </div>
                    <!-- edit and delete button for custom field configuration -->
                    <div class="d-flex flex-column" style="gap: 8px;">
                      <b-btn size="sm" variant="light" class="my-auto show-edit" @click="showToast(`custom-field-${index}-toast`)" style="border-radius: 4px; background: rgba(220, 218, 213, 0.30); padding: 1.5px 5px; border: none; margin-left: 13px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                          <g opacity="0.3">
                            <path d="M7.36672 1.99969L2.80561 6.82746C2.63339 7.0108 2.46672 7.37191 2.43339 7.62191L2.22783 9.42191C2.15561 10.0719 2.62228 10.5164 3.26672 10.4052L5.05561 10.0997C5.30561 10.0552 5.65561 9.87191 5.82783 9.68302L10.3889 4.85524C11.1778 4.02191 11.5334 3.07191 10.3056 1.9108C9.08338 0.760798 8.15561 1.16635 7.36672 1.99969Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.60559 2.80566C6.84448 4.339 8.08892 5.51122 9.63337 5.66678" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.66663 12.2227H11.6666" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                        </svg>
                      </b-btn>
  
                      <b-btn size="sm" variant="light" class="my-auto show-edit" @click="removeCustomField(index)" style="border-radius: 4px; background: rgba(220, 218, 213, 0.30); padding: 1.5px 5px; border: none; margin-left: 13px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                          <g opacity="0.7">
                            <path d="M4.89444 1.11133L2.88333 3.12799M8.43889 1.11133L10.45 3.12799" stroke="#DE3535" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.11111 4.36111C1.11111 3.33333 1.66111 3.25 2.34445 3.25H10.9889C11.6722 3.25 12.2222 3.33333 12.2222 4.36111C12.2222 5.55556 11.6722 5.47222 10.9889 5.47222H2.34445C1.66111 5.47222 1.11111 5.55556 1.11111 4.36111Z" stroke="#DE3535"/>
                            <path d="M5.42222 7.77789V9.75011M7.97777 7.77789V9.75011M1.94444 5.55566L2.72778 10.3557C2.90555 11.4334 3.33333 12.2223 4.92222 12.2223H8.27222C10 12.2223 10.2556 11.4668 10.4556 10.4223L11.3889 5.55566" stroke="#DE3535" stroke-linecap="round"/>
                          </g>
                        </svg>
                      </b-btn>
                    </div>
                  </div>
                </div>

                <!-- custom field configuration -->
                <b-toast
                  :id="`custom-field-${index}-toast`"
                  no-close-button
                  static
                  v-click-outside="{
                    exclude: `custom-field-${index}-toast`,
                    handler: 'hideToast',
                    toastId: `custom-field-${index}-toast`,
                    index: index
                  }"
                  backdrop-filter
                  no-auto-hide
                  style="position: absolute; z-index: 6; border-radius: 12px; border: 1px solid rgba(220, 218, 213, 0.30); background: #FFF; box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.30); width: 365px;"
                  class="bg-light ml-5"
                  body-class="p-0"
                  >
                  <div :ref="`custom-field-${index}-toast`" class="py-3 px-3">
                    <h3 class="font-14px mb-3" style="color: #000; font-weight: 400; line-height: 20px;">Select Type</h3>

                    <hr class="my-0" style="border-color: rgba(220, 218, 213, 0.30);">

                    <div style="margin-top: 12px;">
                      <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.70);" for="field_type">Field Type</label>
                      <b-dropdown variant="white" dropright id="dropdown-1" :text="fieldTypeOption[form.customizePageDetails.custom_fields.fields[index].type]" class="d-block custom-fields" :toggle-class="{'border-danger': validateCustomFieldState('type', index) === false, 'w-100 field-type text-left font-14px': true}" menu-class="p-3">
                        <div class="text-14px" style="font-weight: 400; line-height: 20px;">Select Input Type</div>

                        <hr class="mb-0" style="border-color: rgba(220, 218, 213, 0.30);">

                        <b-dropdown-item v-for="(field, idx) in fieldTypes" :key="field.value" @click="typeChange(index, field.value)" :style="{borderBottom: idx !== fieldTypes.length - 1 ? '1px solid rgba(220, 218, 213, 0.30)' : '', width: '285px'}">
                          <div class="d-flex" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30); width: 36px; height: 36px; margin-right: 24px;">
                            <img :src="field.icon" :alt="field.value" class="m-auto">
                          </div>
                          <div class="my-auto">
                            {{ field.text }}
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-form-invalid-feedback :state="validateCustomFieldState('type', index)">
                        Field Type is required.
                      </b-form-invalid-feedback>
                    </div>

                    <hr style="border-color: rgba(220, 218, 213, 0.30);">

                    <b-form-group class="mb-0" :label="$t('views.payment_pages.dropdown_options')" v-if="field.type == 'dropdown'" description="Press enter to add option">
                      <b-input
                        v-model="form.customizePageDetails.custom_fields.fields[index].option_text"
                        @keyup.enter="addOption($event, index)"
                        type="text"
                        size="sm"
                        class="font-14px"
                        :state="validateCustomFieldState('option_text', index)"
                        style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"
                      ></b-input>
                      <div class="mt-2" v-show="form.customizePageDetails.custom_fields.fields[index].drop_down_options.options.length > 0">
                        <b-badge v-for="(option, key) in form.customizePageDetails.custom_fields.fields[index].drop_down_options.options" class="font-size-12 mr-1 mb-1" :key="key">
                          {{ option.name }}
                          <span style="cursor: pointer;" @click="removeOption(key, index)"><i class="mdi mdi-close"></i></span>
                        </b-badge>
                      </div>
                    </b-form-group>
                    <b-form-invalid-feedback :state="validateCustomFieldState('option_text', index)">
                      Duplicate value.
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback :state="$v.form.customizePageDetails.custom_fields.fields.$each[index].drop_down_options.options.$dirty ? !$v.form.customizePageDetails.custom_fields.fields.$each[index].drop_down_options.$error : null">
                      Dropdown Options are required.
                    </b-form-invalid-feedback>

                    <hr v-show="field.type == 'dropdown'" style="border-color: rgba(220, 218, 213, 0.30);">

                    <div>
                      <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.70);" for="field_title">Field Title</label>
                      <b-input v-model="form.customizePageDetails.custom_fields.fields[index].title" :state="validateCustomFieldState('title', index)" @mousewheel.prevent class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                      <b-form-invalid-feedback :state="validateCustomFieldState('title', index)">
                        Field Title is required.
                      </b-form-invalid-feedback>
                    </div>

                    <hr style="border-color: rgba(220, 218, 213, 0.30);">

                    <div v-if="field.type !== 'checkbox'">
                      <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.70);" for="field_description">Title Description</label>
                      <b-input v-model="form.customizePageDetails.custom_fields.fields[index].description" @mousewheel.prevent class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                    </div>

                    <hr style="border-color: rgba(220, 218, 213, 0.30);">

                    <div>
                      <label class="font-12px" style="line-height: 16px; margin-bottom: 2px; color: rgba(0, 0, 0, 0.70);" for="field_tooltip">Tooltip</label>
                      <b-input v-model="form.customizePageDetails.custom_fields.fields[index].tooltip" @mousewheel.prevent class="font-14px" min="0" style="border-radius: 12px; border: 1.5px solid rgba(220, 218, 213, 0.50); height: 44px; line-height: 20px;"></b-input>
                    </div>

                    <hr style="border-color: rgba(220, 218, 213, 0.30);">

                    <div class="d-flex mb-2">
                      <label class="my-auto font-14px mr-2">Mark this field as optional</label><switch-input class="switch-input" v-model="form.customizePageDetails.custom_fields.fields[index].is_mandatory" :value="form.customizePageDetails.custom_fields.fields[index].is_mandatory" />
                    </div>
                  </div>
                </b-toast>
              </div>
            </draggable>
            <!-- cusom fields end -->

            <!-- button for adding new custom field -->
            <b-btn variant="link" class="text-chai p-0 font-14px ml-4 mb-4" @click="addNewCustomField" style="margin-top: 24px; line-height: 20px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M5 10H15M10 15V5" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Add New Field
            </b-btn>
          </div>

          <!-- advanced settings customization -->
          <div>
            <h1 class="my-auto" style="line-height: 24px; font-size: 18px; font-weight: 400; color: #000;">Advanced Settings</h1>

            <hr style="border-color: rgba(220, 218, 213, 0.30); margin-top: 18px; margin-bottom: 24px;">

            <b-form-checkbox v-model="form.customizePageDetails.send_email_confirmation" :value="true" :unchecked-value="false" class="font-14px" style="line-height: 20px;">Send payment confirmation email to customer</b-form-checkbox>

            <hr style="border-color: rgba(220, 218, 213, 0.30);">

            <!-- payment confirmation emails / broker emails -->
            <div>
              <b-form-checkbox v-model="form.customizePageDetails.send_broker_email_notification" :value="true" :unchecked-value="false" class="font-14px" style="line-height: 20px;">Send payment confirmation email to multiple emails</b-form-checkbox>
  
              <div v-if="form.customizePageDetails.send_broker_email_notification">
                <div class="email-list p-2" :class="{ 'invalid': $v.brokerNotificationEmail.$anyError }">
                    <b-form-input
                        v-model="brokerNotificationEmail"
                        type="email"
                        placeholder="Add Email Here..."
                        class="border-0"
                        autocomplete="off"
                        @keypress.enter="addEmailToBrokerNotification"
                    ></b-form-input>
                    <div class="d-flex flex-wrap">
                        <div class="email-text m-1" v-for="(item, index) in form.customizePageDetails.broker_emails" :key="index">
                            {{ item }}
                            <span class="ml-2 cursor-pointer" @click="removeEmailFromBrokerNotification(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g clip-path="url(#clip0_1_70383)">
                                        <path d="M6.8775 11.1225L11.1225 6.8775M11.1225 11.1225L6.8775 6.8775M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke="black" stroke-opacity="0.3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_70383">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <b-form-invalid-feedback :state="$v.brokerNotificationEmail.$dirty ? !$v.brokerNotificationEmail.$error : null">
                  Please enter valid Email.
                </b-form-invalid-feedback>
              </div>
            </div>

            <hr style="border-color: rgba(220, 218, 213, 0.30);">

            <!-- custom page ref -->
            <div v-if="!isEdit">
              <b-form-checkbox v-model="isCustomUrl" :value="true" :unchecked-value="false" class="font-14px" style="line-height: 20px;">Set up Custom URL for this page</b-form-checkbox>
  
              <div v-if="isCustomUrl" class="mt-2 d-flex">
                <div :class="{ 'invalid': $v.form.customizePageDetails.page_ref.$dirty ? $v.form.customizePageDetails.page_ref.$error : null }" style="height: 44px;line-height: 44px;background: #f4f4f2;margin-bottom: 0px;border-bottom-left-radius: 12px;border-top-left-radius: 12px;color: #000;padding-left: 16px;font-size: 14px;padding-right: 15px;border: 1px #DCDAD5 solid;border-right: none; white-space: nowrap;">{{ pageBaseUrl }}/?ref=</div>
                <div class="d-flex justify-content-between" :class="{ 'invalid': $v.form.customizePageDetails.page_ref.$dirty ? $v.form.customizePageDetails.page_ref.$error : null }" style="border: 1px solid #DCDAD5; border-radius: 0px 12px 12px 0px; padding-right: 6px;">
                  <b-input type="text" v-model="form.customizePageDetails.page_ref" id="custom-page-ref" class="flex-fill my-auto" style="width: 70%; border: none; overflow: hidden; text-overflow: ellipsis;" maxlength="40"></b-input>
                  <b-btn @click="checkCustomUrl" class="p-0 border-0 my-auto" variant="white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 44 44" fill="none">
                      <g opacity="0.3">
                        <circle cx="22" cy="22" r="22" fill="#DCDAD5"/>
                        <circle cx="22" cy="22" r="21.5" stroke="black" stroke-opacity="0.5"/>
                      </g>
                      <path d="M23.8225 17.4473L28.375 21.9998L23.8225 26.5523M15.625 21.9998H28.2475" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </b-btn>
                </div>
              </div>
              <b-form-invalid-feedback :state="$v.form.customizePageDetails.page_ref.$dirty ? !$v.form.customizePageDetails.page_ref.$error : null">
                <span v-if="!$v.form.customizePageDetails.page_ref.requiredIf">
                  Custom URL is required.
                </span>
                <span v-else>
                  Invalid custom URL. Please make sure it contains alphabets and numbers only and length is between 10 and 40 characters!
                </span>
              </b-form-invalid-feedback>
              <div style="margin-top: 5px;">
                <span style="opacity: 0.50;color: black;font-size: 12px;">Let your customers know, this URL belongs to you by using SLUG</span>
              </div>
            </div>

          </div>

          <div class="mt-4 d-flex justify-content-between">
            <b-btn variant="white" class="p-0 my-auto" @click="goPrevious">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.97499 4.94141L2.91666 9.99974L7.97499 15.0581M17.0833 9.99974H3.05833" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </b-btn>
            <b-btn class="px-3 py-2" @click="goToNext" style="font-size: 14px; font-weight: 400; line-height: 20px;" variant="primary">
              Next
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M12.0248 4.94141L17.0832 9.99974L12.0248 15.0581M2.9165 9.99974H16.9415" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </b-btn>
          </div>
        </div>

        <div v-if="activeTab === 'Branding'">
          <h1 class="my-auto" style="line-height: 24px; font-size: 18px; font-weight: 400; color: #000;">Branding</h1>

          <hr style="border-color: rgba(220, 218, 213, 0.30); margin-top: 18px;">

          <!-- Brand Colour configuration -->
          <div>
            <label class="font-14px mb-1" style="line-height: 24px; font-weight: 400;">Brand Colour</label>

            <p class="font-12px" style="color: rgba(0, 0, 0, 0.50); line-height: 16px; font-weight: 325;">Select a theme color for your brand. If no color is chosen, the default theme color will be applied.</p>

            <!-- primary and secondary color picker -->
            <div class="row">
              <!-- primary color -->
              <div class="col-md-6">
                <label class="font-12px" style="line-height: 24px;">Primary Colour</label>
                <div class="d-flex" style="border-radius: 12px; border: 1px solid #DCDAD5;">

                  <!-- color picker start -->
                  <div :style="`border-radius: 12px; border: 1px solid #DCDAD5; background: ${form.branding.brand_primary_color}; width: 44px; height: 44px; margin-right: 16px;`">
                    <input type="color" v-model="form.branding.brand_primary_color" ref="brand_primary_color" name="brand_primary_color" style="width: 44px; height: 44px; opacity: 0;">
                  </div>
                  <!-- color picker end -->

                  <!-- input color hexcode -->
                  <div class="my-auto d-flex">
                    <b-input type="text" v-model="form.branding.brand_primary_color" class="font-14px inline-flex p-0 color-input" style="border: none; line-height: 24px; height: auto;" />
                  </div>
                </div>
              </div>

              <!-- secondary color -->
              <div class="col-md-6">
                <label class="font-12px" style="line-height: 24px;">Secondary Colour</label>

                <div class="d-flex" style="border-radius: 12px; border: 1px solid #DCDAD5;">

                <!-- color picker start -->
                <div :style="`border-radius: 12px; border: 1px solid #DCDAD5; background: ${form.branding.brand_secondary_color}; width: 44px; height: 44px; margin-right: 16px;`">
                  <input type="color" v-model="form.branding.brand_secondary_color" ref="brand_primary_color" name="brand_primary_color" style="width: 44px; height: 44px; opacity: 0;">
                </div>
                <!-- color picker end -->

                <!-- input color hexcode -->
                <div class="my-auto d-flex">
                  <b-input type="text" v-model="form.branding.brand_secondary_color" class="font-14px inline-flex p-0 color-input" style="border: none; line-height: 24px; height: auto;" />
                </div>
                </div>
              </div>
            </div>
            <!-- color picker end -->
          </div>

          <hr style="border-color: rgba(220, 218, 213, 0.30); margin-top: 24px; margin-bottom: 24px;">

          <!-- Appearance / Theme configuration -->
          <div>
            <label class="font-14px mb-1" style="line-height: 24px; font-weight: 400;">Appearance</label>

            <p class="font-12px" style="color: rgba(0, 0, 0, 0.50); line-height: 16px; font-weight: 325; margin-bottom: 18px;">Choose your payment page theme as you wish</p>

            <div class="tab-content-section" style="width: fit-content; border-radius: 12px; padding: 2px; height: 44px;">
              <b-btn variant="primary" @click="() => form.branding.is_dark_mode = false" :class="{'tab-active': !form.branding.is_dark_mode}" class="tab-button px-5">Light</b-btn>
              <b-btn variant="primary" @click="() => form.branding.is_dark_mode = true" :class="{'tab-active': form.branding.is_dark_mode}" class="tab-button px-5">Dark</b-btn>
            </div>
          </div>

          <hr style="border-color: rgba(220, 218, 213, 0.30); margin-top: 24px; margin-bottom: 16px;">

          <!-- Paynow button text config -->
          <div>
            <label class="font-14px mb-1" style="line-height: 24px; font-weight: 400;">Pay Button</label>
            <p class="font-12px" style="color: rgba(0, 0, 0, 0.50); line-height: 16px; font-weight: 325; margin-bottom: 16px;">Set the Payment button text as you wish</p>

            <div class="d-flex" :class="{'border-danger': !form.branding.pay_button_text}" style="border-radius: 12px; border: 1px solid #DCDAD5;">
              <b-input v-model="form.branding.pay_button_text" placeholder="add button text here" class="w-50" style="border: none; border-radius: 12px;"></b-input>
              <b-btn class="w-50" :style="`background-color: ${form.branding.brand_primary_color} !important; color: #FFF !important; border: none; border-radius: 12px;`">{{ form.branding.pay_button_text }}</b-btn>
            </div>
            <b-form-invalid-feedback :state="!!form.branding.pay_button_text">
              Please enter button text.
            </b-form-invalid-feedback>
          </div>

          <hr style="border-color: rgba(220, 218, 213, 0.30); margin-top: 24px; margin-bottom: 24px;">

          <label class="font-14px mb-1" style="line-height: 24px; font-weight: 400;">Your Logo</label>

          <p class="font-12px" style="color: rgba(0, 0, 0, 0.50); line-height: 16px; font-weight: 325; margin-bottom: 16px;">Set your brand logo on the page.</p>

          <div class="d-flex mb-4">
            <!-- logo preview -->
            <div class="d-flex" style="border-radius: 10px; border: 1px solid rgba(220, 218, 213, 0.50); background: #FFF; box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.30); height: 74px; width: 74px; margin-right: 24px;">
              <img class="m-auto" v-if="form.merchant_details.logo" :src="form.merchant_details.logo" alt="icon" style="max-height: 75px; max-width: 75px;">
            </div>
            <div>
              <div class="d-flex mb-2">
                <!-- logo upload -->
                <div style="border-radius: 12px; border: 1px dashed #DCDAD5; height: 36px; width: 270px;" class="mr-3">
                  <input type="file" @change="uploadFile" style="height: 36px; position: absolute; opacity: 0; width: 270px;">
                  <div class="font-12px text-center" style="line-height: 24px; padding-top: 6px; padding-bottom: 6px;"><span class="text-chai">Upload a file</span> or drag & drop</div>
                </div>
                <b-btn variant="link" class="text-chai font-12px p-0 my-auto" @click="removeLogo" style="line-height: 20px; font-weight: 400;">Remove</b-btn>
              </div>
              <p class="m-0" style="font-size: 10px; font-style: italic; font-weight: 325; color: rgba(0, 0, 0, 0.50); line-height: 16px;">Maximum file size: 1MB</p>
              <p class="m-0" style="font-size: 12px; font-weight: 325; color: rgba(0, 0, 0, 0.50); line-height: 16px;">Choose a square image of min. dimensions 256x256 px.</p>
            </div>
          </div>

          <hr style="border-color: rgba(220, 218, 213, 0.30);">

          <div class="mt-4 d-flex justify-content-between">
            <b-btn variant="white" class="p-0 my-auto" @click="goPrevious">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.97499 4.94141L2.91666 9.99974L7.97499 15.0581M17.0833 9.99974H3.05833" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </b-btn>
            <!-- <b-btn class="px-3 py-2" @click="saveChanges" style="font-size: 14px; font-weight: 400; line-height: 20px;" variant="primary">
              Save Changes
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M12.0248 4.94141L17.0832 9.99974L12.0248 15.0581M2.9165 9.99974H16.9415" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </b-btn> -->
          </div>
        </div>
      </div>

      <!-- page preview -->
      <div class="mb-auto" style="border-left: 1px solid #DCDAD5; background: rgba(220, 218, 213, 0.10); width: 57%; padding-bottom: 113px; height: 70vh;">
        <div class="d-flex justify-content-between" style="margin-top: 24px; padding-left: 24px; padding-right: 24px;">
          <h2 class="font-14px mb-0" style="font-weight: 350; line-height: 20px; color: #000;">Preview</h2>
          <b-btn variant="white" class="my-auto p-0 font-12px" style="line-height: 16px;" v-b-modal.full-page-preview>
            Full page view
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.9999 13.5138C10.3532 13.5138 12.5466 12.1271 14.0732 9.72714C14.6732 8.78714 14.6732 7.20714 14.0732 6.26714C12.5466 3.86714 10.3532 2.48047 7.9999 2.48047C5.64656 2.48047 3.45323 3.86714 1.92656 6.26714C1.32656 7.20714 1.32656 8.78714 1.92656 9.72714C3.45323 12.1271 5.64656 13.5138 7.9999 13.5138Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </b-btn>
        </div>
        <hr style="border-color: #DCDAD5;" class="mb-4">

        <PaymentPagePreview :formData="payloadForPaymentPage" />
      </div>

      <b-modal
        id="full-page-preview"
        size="xl"
        no-close-on-backdrop
        hide-footer
        hide-header
      >
      <div style="height: 110vh;">
        <div class="d-flex justify-content-end">
          <img @click="$bvModal.hide('full-page-preview')" class="modal-close" src="@/assets/images/close.svg" alt="close" />
        </div>
        <PaymentPagePreview fullpage class="mt-4" :formData="payloadForPaymentPage" />
      </div>
      </b-modal>
    </div>

  </Layout>
</template>

<style lang="scss" scoped>
.menu-content {
    /* border-radius: 12px; */
    padding: 12px;
    /* background: rgba(220, 218, 213, 0.30);
    display: flex;
    justify-content: space-between; */
    margin-bottom: 24px;
    /* position: relative;
    flex-wrap: wrap;
    gap: 10px; */
}
input::placeholder {
  color: rgba(0, 0, 0, 0.30);
}
.tab-content-section {
    /* border-radius: 11px;
    border: 1px solid #DCDAD5;
    background: #FFF;
    display: flex;
    flex-wrap: wrap;
    gap: 4px; */
    padding: 3px;
}

.tab-button {
    /* background-color: #FFF; */
    padding: 8px 12px;
    /* line-height: 20px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    border: none; */
}

/* .tab-button.tab-active {
    background-color: #000;
    color: #FFF;
} */
::v-deep .ql-container {
  border: none !important;
}

::v-deep .ql-toolbar {
  border: none !important;
  background: rgba(220, 218, 213, 0.30);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
::v-deep .quillWrapper {
  border-radius: 12px;
  border: 1px solid #DCDAD5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
::v-deep .ql-editor {
  line-height: 16px;
  font-size: 14px;
  font-weight: 350;
}
::v-deep .ql-blank::before {
  color: rgba(0, 0, 0, 0.30) !important;
  font-style: normal !important;
}
/* ::v-deep .ql-formats > * {
  line-height: 16px;
  font-size: 12px !important;
} */
.show-border:hover {
  padding: 8px;
  border-radius: 12px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
  transition: padding .3s, box-shadow .3s;
}
.show-edit {
  display: none;
}
.show-border:hover .show-edit {
  display: unset;
}

.email-list {
    border-radius: 15px;
    border: 1px solid #DCDAD5;
    min-height: 44px;
    margin-top: 10px;
}
.email-text {
    border-radius: var(--8, 8px);
    border: 1px solid rgba(220, 218, 213, 0.50);
    background: rgba(220, 218, 213, 0.30);
    padding: 8px 12px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
}
.invalid {
    border-color: #ff3d60 !important;
}

#custom-page-ref:hover {
  overflow: auto !important;
  text-overflow: unset !important;
}
::v-deep .custom-radio .custom-control-label::before {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: 2px 0;
}
::v-deep .custom-radio .custom-control-label::after {
  height: 8px;
  width: 8px;
  margin: 4px 2px;
  border-radius: 50%;
  /* background: white; */
  background-image: none !important;
}
::v-deep .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #FC6B2D;
}
::v-deep .custom-radio .custom-control-input {
  height: 12px;
  width: 12px;
  margin-top: 4px;
}

::v-deep .custom-radio .custom-control-label {
  padding-left: 0;
  line-height: 16px;
}

::v-deep .switch-input {
  height: 20px;
  width: 36px;

  input:checked + .slider {
    background-color: #FFF0EA;
    border: 1px solid rgba(220, 218, 213, 0.50);

    &:before {
      background-color: #FC6B2D;
      transform: translateX(15px);
    }
  }

  .slider {
    height: 20px;
    width: 36px;
  }

  .slider:before {
    height: 16px;
    width: 16px;
  }
}
::v-deep .field-type {
  border-radius: 12px;
  border: 1.5px solid rgba(220, 218, 213, 0.50);
  height: 44px;
  line-height: 20px;
  background: #fff;
  color: #000 !important;
  font-size: 14px;
  font-weight: 350;
}
::v-deep .custom-fields .dropdown-item {
  display: flex !important;
  padding: 8px 0px 8px 0px !important;
  height: auto !important;
  border: none !important;
}
::v-deep .preview-custom-checkbox .custom-control-label {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  word-wrap: break-word;
  max-width: 300px;

  &::before {
    border-color: rgba(0, 0, 0, 0.30) !important;
  }
}

::v-deep .color-input {
  color: #000;
  // &::first-letter {
  //   color: rgba(0, 0, 0, 0.30) !important;
  // }
}
</style>