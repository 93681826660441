import { ReconAnalyticsService } from '../../helpers/backend/recon_analytics.service';

import {
    DOWNLOAD_RECON_SUMMARY_TABLE_RECORDS,
    FETCH_RECON_SUMMARY,
    FETCH_RECON_SUMMARY_TABLE_RECORDS,
    // EXPORT_RECON_SUMMARY,
    SEND_RECON_SUMERY_DATA_ON_EMAIL
} from '../actions.type';

import { SET_RECON_SUMMARY, SET_RECON_TRANSACTIONS, SET_RECON_COLUMNS } from '../mutations.type';

export const state = {
    summary: {
        dataFields: [],
        dataNodes: [],
        lastUpdatedDate: ""
    },
    tableData: {
        content: [],
        totalElements: 0,
        totalPages: 0,
        size: 0,
        page: 0,
    },
    additionalColumns: null
};

export const mutations = {
    [SET_RECON_SUMMARY](state, summary) {
        state.summary.dataFields = summary.dataFields || [];
        state.summary.dataNodes = summary.dataNodes || [];
        state.summary.lastUpdatedDate = summary.last_updated_at || "";
    },
    [SET_RECON_TRANSACTIONS](state, data) {
        state.tableData = {
            content: data.content,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            size: data.size,
            page: data.page,
        }
    },
    [SET_RECON_COLUMNS](state, data) {
        state.additionalColumns = data;
    }
};

export const getters = {
    reconSummary(state) {
        return state.summary;
    },
    reconSummaryTable(state) {
        return state.tableData.content;
    },
    reconAdditionalColumns(state) {
        return state.additionalColumns;
    },
    totalElements(state) {
        return state.tableData.totalElements;
    }
};

export const actions = {
    [FETCH_RECON_SUMMARY](context, payload) {
        return new Promise((resolve, reject) => {
            payload.environment = context.rootState.auth.environment;
            return ReconAnalyticsService.getReconSummary(context.rootState.auth.currentUser.iamportKey, payload)
            .then(response => {
                if(response.data) {
                    context.commit(SET_RECON_SUMMARY, response.data);
                }
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    [FETCH_RECON_SUMMARY_TABLE_RECORDS](context, payload) {
        return new Promise((resolve, reject) => {
            payload.environment = context.rootState.auth.environment;
            context.commit(SET_RECON_TRANSACTIONS, { content: [], totalElements: state.tableData.totalElements, totalPages: state.tableData.totalPages, size: state.tableData.size, page: state.tableData.page });
            return ReconAnalyticsService.getReconSummaryTableRecords(context.rootState.auth.currentUser.iamportKey, payload)
            .then(response => {
                context.commit(SET_RECON_COLUMNS, response.columns);
                context.commit(SET_RECON_TRANSACTIONS, response.data);
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    [DOWNLOAD_RECON_SUMMARY_TABLE_RECORDS](context, payload) {
        return new Promise((resolve, reject) => {
            payload.environment = context.rootState.auth.environment;
            return ReconAnalyticsService.downloadReconSummaryTableRecords(context.rootState.auth.currentUser.iamportKey, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    [SEND_RECON_SUMERY_DATA_ON_EMAIL](context, payload) {
        return new Promise((resolve, reject) => {
            payload.environment = context.rootState.auth.environment;
            return ReconAnalyticsService.sendReconSumeryDataOnEmail(context.rootState.auth.currentUser.iamportKey, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    // [EXPORT_RECON_SUMMARY]({ commit, rootState }, payload) {
	// 	return new Promise((resolve, reject) => {
    //         payload.environment = rootState.auth.environment;
    //         const iamportKey = rootState.auth.currentUser.iamportKey;

    //         ReconAnalyticsService.downloadReconSummary(iamportKey, payload).then((response) => {
    //             resolve(response);
    //         }).catch((error) => {
    //             const errorObject = JSON.parse(error.message)
    //             commit('notification/error', errorObject.Message, { root: true })
    //             reject(error);
    //         })
	// 	})
	// },
};

export const namespaced = true;